import React from 'react';
import { Container, ResponsiveEmbed, Row } from 'react-bootstrap';
import { Footer, Layout, Navbar, PageHeader, Sponsor } from '@components';
import { videoFallback } from '@variables';
import '@scss/help-page.scss';

const HelpPage = () => <Layout className="help-page">
  <Navbar />
  <PageHeader
    title="Help and Agenda"
    paddingBottom="74px"
    titleTransform="uppercase"
  />

  <Container style={{ marginBottom: `1rem`, marginTop: `3rem` }}>
    <Row>
      <ResponsiveEmbed aspectRatio="16by9">
        <iframe
          title="Walkthrough"
          src={
            videoFallback ?
              `` :
              `https://player.vimeo.com/video/698241288?h=631cb769ac`
          }
          className="embed-responsive-item"
          allow="autoplay; fullscreen"
          allowFullScreen />
      </ResponsiveEmbed>
    </Row>
  </Container>

  <Sponsor />
  <Footer />

</Layout>;

export default HelpPage;
